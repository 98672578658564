






























































































































































import useSelectItems from "@/use/selectItems";
import {
  defineComponent,
  onMounted,
  reactive,
  watch,
} from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { v4 as uuid } from "uuid";

export default defineComponent({
  components: {
    MRemoveBulkNfc: () =>
      import("@/components/molecules/m-remove-bulk-nfc.vue"),
    MParticipantMailExport: () =>
      import(
        "@/components/molecules/participant/m-participant-mail-export.vue"
      ),
    OReportGenerate: () =>
      import("@/components/organisms/event/report/o-report-generate.vue"),
    MSendSms: () => import("@/components/molecules/participant/m-send-sms.vue"),
  },

  props: {
    participantList: {
      type: Array,
      required: false,
      default: "",
    },
  },
  setup(props, { root, emit }) {
    const state = reactive({
      messages: [],
      totalMessages: 0,
      error: false as boolean | number,
      participantList: [] as any,
      participantGroups: [],
      totalGroups: 0,
      removeNfc: false,
      mailExport: false,
      exportedEmails: [],
      generateReport: false,
      smsTemplates: [],
      sendSMSDialog: false,
    });

    const model = reactive({
      alertTemplate: "",
      participantStatus: "",
      participantGroups: [],
      action: "",
      otherOptions: "",
    });

    watch(
      () => props.participantList,
      () => {
        state.participantList = props.participantList.map(
          (item: any) => item["id"]
        );
      }
    );

    watch(
      () => props.participantList,
      () => {
        state.participantList = props.participantList.map(
          (item: any) => item["id"]
        );
      }
    );

    const actionItems = [
      {
        name: root.$tc("layout.misc.addToGroup"),
        value: "add",
      },
      {
        name: root.$tc("layout.misc.removeFromGroup"),
        value: "remove",
      },
    ];

    const { participantStatusItems } = useSelectItems({ root });

    const otherOptions = [
      {
        name: root.$tc("panel.event.participant.assignNfc.removeCard"),
        value: "remove",
      },
      {
        name: root.$tc("panel.event.participant.list.mailExport"),
        value: "mailExport",
      },
      {
        name: root.$tc("panel.event.participant.list.generateReport"),
        value: "report",
      },
    ];

    const emitFetch = () => {
      emit("fetch");
    };

    const getErrorMessage = (code: number) => {
      switch (code) {
        case 400:
          return `${root.$tc("layout.errors.400")}`;
        case 401:
          return `${root.$tc("layout.errors.401")}`;
        case 500:
          return `${root.$tc("layout.errors.500")}`;
        default:
          return `${root.$tc("layout.errors.default")}`;
      }
    };

    // Start fetch alert messages

    const fetchMessages = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`/message-template/${root.$route.params.id}/select`)
        .then(({ data: { messages, total } }) => {
          state.messages = messages;
          state.totalMessages = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.messages = [];
            state.totalMessages = 0;
          } else {
            console.log(error);
          }
        });
    };

    onMounted(fetchMessages);

    // End fetch alert messages

    const fetchSMSTemplates = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`event/${root.$route.params.id}/sms-template`)
        .then(({ data }) => {
          state.smsTemplates = data;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(fetchSMSTemplates);

    const fetchParticipangGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      axiosInstance
        .get(`/event/${root.$route.params.id}/participant/group/select`)
        .then(({ data: { groups, total } }) => {
          state.participantGroups = groups;
          state.totalGroups = total;
        })
        .catch((error) => {
          if (error.response.status === 404) {
            state.participantGroups = [];
            state.totalGroups = 0;
          } else {
            console.log(error);
          }
        });
    };

    onMounted(fetchParticipangGroups);

    // Start send message to participants

    const submitAlerts = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        participants: state.participantList,
        messageId: model.alertTemplate,
        eventId: root.$route.params.id,
        type: "Bulk",
      };

      axiosInstance
        .post("mail-task", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          model.alertTemplate = "";

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.emailsAddedToQueue"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    // End send message to participants

    // Start change participants status

    const submitStatus = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        participants: state.participantList,
        state: model.participantStatus,
      };

      axiosInstance
        .put("participant/status", data, {
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          state.participantList = [];
          model.participantStatus = "";
          emitFetch();

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message: root.$tc("layout.misc.participantStatusChanged"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    // End change participants status

    // Start assign participant to groups

    const submitGroups = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      const data = {
        participants: state.participantList,
        groups: model.participantGroups,
      };

      axiosInstance
        .put("participant/group", data, {
          params: { operation: model.action },
          headers: {
            event: root.$route.params.id,
          },
        })
        .then(() => {
          state.error = false;

          state.participantList = [];
          model.participantGroups = [];
          emitFetch();

          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "success",
            message:
              model.action === "add"
                ? root.$tc("layout.misc.addedParticipantsToGroups")
                : root.$tc("layout.misc.removedParticipantsFromGroups"),
          });
        })
        .catch((error) => {
          state.error = error.response.status;
          root.$store.commit("snackbar/PUSH_MESSAGE", {
            id: uuid(),
            color: "error",
            message: getErrorMessage(state.error as number),
          });
        });
    };

    // End assign participant to groups

    const hasAccessTo = (permission: string) =>
      root.$store.getters["user/hasAccessTo"](permission);

    return {
      state,
      model,
      actionItems,
      participantStatusItems,
      otherOptions,
      hasAccessTo,
      submitAlerts,
      submitStatus,
      submitGroups,
      emitFetch,
    };
  },
});
